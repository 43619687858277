import React, { useState, useEffect } from 'react';
import Timer from 'react-compound-timer';
import SoundControl from './SoundControl';
import PauseButton from './PauseButton';
import PlayButton from './PlayButton';
import StopwatchButton from './StopwatchButton';

import './stopwatch.css';
// import { helloSound } from '../utils/sound';
 
const Stopwatch = ({
    onComplete, 
    onAlmostComplete,
    onPaused,
    cycleTime,
    warningTime,
    audioSample
}) => {
  const [paused, pauseTimer] = useState(true);
  const [soundEnabled, enableSound] = useState(true);

  const isResetting = cycleTime.minutes === 0 && cycleTime.minutes === 0;

  if (isResetting) {
    // pauseTimer(true);
    // This whole component needs to be refactored cleaned up.
    // Currently duplicating code for the resetting state... *sad face*
    if (!paused) { pauseTimer(true); }
    return (
      <div className="stop-watch">
        <div>
          <SoundControl 
            on={soundEnabled} 
            onClick={enableSound}
          />
        </div>
        <div>
          <p>00:00</p>
        </div>
        <div>
        </div>
      </div>
    );
  } else {
  return (
    <Timer
      formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`}
      direction="backward"
      startImmediately={false}
      initialTime={
        cycleTime.minutes * 60 * 1000 + cycleTime.seconds * 1000
      }
      checkpoints={[
          {
              time: warningTime.seconds * 1000,
              callback: () => onAlmostComplete()
          },
          {
              time: 0,
              callback: () => onComplete()
          }
      ]}
    >
      {({ start, resume, pause, stop, reset, timerState }) => (
      <div className="stop-watch">
          <div>

            <SoundControl 
              on={soundEnabled} 
              onClick={enableSound}
            />
          </div>
          <div>
            <p>
              { isResetting ? '-00:00-' : 
                  <>
              <Timer.Minutes />:<Timer.Seconds />
                  </>
              }
            </p>
          </div>
          <div>
            <StopwatchButton 
              paused={paused}
              onPause={() => {
                  pause(); 
                  pauseTimer(true) 
              }}
              onResume={() => {
                  resume();
                  pauseTimer(false);
              }}
            />
          </div>
        {!paused && soundEnabled &&
          <audio autoPlay={!paused} loop={!paused}>
            <source src={audioSample} type="audio/mpeg"/>
            Your browser does not support the audio element.
          </audio>
        }
      </div>
      )}
    </Timer>
  );
  }
}
 
export default Stopwatch;
