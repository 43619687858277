import React from "react"
import './ModeInstructions.css';

const ModeInstructions = ({ tonalCenter, modeName, string }) => {
  return (
    <p className="instructions">
      Play <b>{tonalCenter}</b> <b className="mode-name">{modeName}</b> mode on 
      the <b>{string}</b> string.
    </p>
  );
}

export default ModeInstructions
