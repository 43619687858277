import React from "react"
import './UButton.css'

const UButton = ({ children, title, onClick }) => (
  <button 
    title={title}
    className="u-button"
    onClick={onClick}
  >
    {children}
  </button>
);

UButton.defaultProps = {
}

export default UButton
