import React, { useEffect } from 'react';
import PauseButton from './PauseButton';
import PlayButton from './PlayButton';

const StopwatchButton = ({ paused, onResume, onPause }) => { 
  const handleUserKeyPress = (e) => {
    if (e.key === ' ') {
      if (paused) {
        onResume();
      } else {
        onPause(); 
      }
      e.preventDefault();
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  });

  return (
    <button
      title={`${!paused ? 'Pause' : 'Resume'} timer`}
      onClick={() => { 
        if (!paused) { 
          onPause();
        } else { 
          onResume();
        }
      }
      }
    >
      {paused ? <PlayButton /> : <PauseButton />}
    </button>
  );
}

export default StopwatchButton;
