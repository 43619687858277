import React from "react"
import './OpSelector.css'

const OpSelector = ({ initialOpMode, onOpSelected }) => (
  <div className="op-selector">
    { false &&
    <label>            
      <input 
        type="radio" 
        name="op-mode" 
        onClick={() => onOpSelected('demo')}
        defaultChecked={initialOpMode === 'demo'}
      />
      <span className="radio__control">20</span>
    </label>
    }
    <label>
      <input 
        type="radio" name="op-mode"
        onChange={() => onOpSelected('quick')}
      />
      <span className="radio__control">02:00</span>
    </label>
    <label>
      <input 
        type="radio" 
        name="op-mode"
        onChange={() => onOpSelected('long')}
        checked={initialOpMode === 'long'}
      />
      <span className="radio__control">04:00</span>
    </label>
  </div>
);


OpSelector.defaultProps = {
}

export default OpSelector
