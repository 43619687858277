import React, { useState } from "react"
import { Link } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"
import PurchaseCopy from "../components/PurchaseCopy"
import Diagram from '../components/Diagram';
import ModeCard from '../components/ModeCard';
import OpSelector from '../components/OpSelector';
import Stopwatch from '../components/Stopwatch';
import ModeInstructions from '../components/ModeInstructions';
import UButton from '../components/UButton';
import { randomMode, randomString, getTonalCenter } from '../utils/engine';
import './index.css';
import { getToneSample } from '../utils/samples';

const IndexPage = () => {
  const [mode, setMode] = useState(randomMode());
  const [string, setString] = useState(randomString());
  const [clockStatus, setClockStatus] = useState('Go!');
  const [opMode, setOpMode] = useState('long');

  const cycleTime = {
    suspended: { minutes:0, seconds: 0 },
    long: { minutes: 4, seconds: 0 },
    quick: { minutes: 2, seconds: 0 },
    demo: { minutes: 0, seconds: 20 } // speed up in dev mode
  };

  const warningTime = {
    long: { minutes: 0, seconds: 30 },
    quick: { minutes: 0, seconds: 30 },
    demo: { minutes: 0, seconds: 10 } // speed up in dev mode
  };

  const almostCompleteHandler = () => {
    // helloSound(1, 1, 2);
    setClockStatus(
      `WARNING! ${warningTime[opMode].seconds} seconds left`
    )
  }

  const completeHandler = () => {
    // helloSound(1, 2, 3); */
    setClockStatus('Completed! Starting next mode in a few seconds...');
    // need a pause here or something...maybe ask the user to 
    // hit a key to say "Continue?"
    setMode(randomMode());
    setString(randomString());
    setTimeout(
      () => setClockStatus('Nice job! You deserve some kudos for practicing! Now keep going and click play to start the next mode.'), 
      5000
    );
  }

  const toneSample = getToneSample(mode.name, 'guitar');

  return (
    <Layout>
      <SEO title="Home" />
      <div className="control-panel">
        <UButton 
          title="Skip to next mode"
          onClick={() => {
            setMode(randomMode());
            setString(randomString());
            setClockStatus('Completed! Resetting next mode in a few seconds...');
            
            setTimeout(
              () => setClockStatus('Go!'), 
              1000
            );
          }}
        >
          NEXT! 
        </UButton>
        <OpSelector 
          initialOpMode={opMode}
          onOpSelected={(newCycle) => { 
            setOpMode(newCycle) 
            setMode(randomMode());
            setString(randomString());
            setClockStatus('Completed! Resetting next mode in a few seconds...');
            setTimeout(
              () => setClockStatus('Go!'), 
              1000
            );
          }} 
        />
        { 
          <Stopwatch 
            cycleTime={
              clockStatus.startsWith('Completed') ? 
                { minutes:0, seconds: 0 } : 
                cycleTime[opMode]
            }
            warningTime={warningTime[opMode]}
            onPaused={console.log}
            onComplete={completeHandler}
            onAlmostComplete={almostCompleteHandler}
            audioSample={toneSample}
          />
        }
      </div>
      <div className="workspace">
        <div>
          <ModeCard 
            mode={mode}
          />
          <Diagram 
            mode={mode} 
            string={string} 
          />
        </div>
        <div>
          <p>
            <em>{clockStatus} </em>
            {clockStatus.startsWith('Go!') && 
                <em>Click play (>) above to start the practice timer</em>
            }
          </p>
          <ModeInstructions
            tonalCenter={getTonalCenter(mode.name)}
            modeName={mode.name}
            string={string}
          />
          <PurchaseCopy showLink={true}>
            This site is designed for guitarists, but may be useful for any 
            musician that wants to study modal improvisation.
            <br/>
            <br/>
            By default, you'll be presented a new mode/string combination every 
            four minutes. For more information, 
            <Link to="how-it-works">see how it works</Link>
          </PurchaseCopy>
        </div>
      </div>

    </Layout>
  );
}

export default IndexPage
