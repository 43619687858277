// simple organ drones (default)
import C from '../audio/c-organ-drone.mp3';
import D from '../audio/d-organ-drone.mp3';
import E from '../audio/e-organ-drone.mp3';
import F from '../audio/f-organ-drone.mp3';
import G from '../audio/g-organ-drone.mp3';
import A from '../audio/a-organ-drone.mp3';
import B from '../audio/b-organ-drone.mp3';

// guitar - chord progressions
import C_guitar from '../audio/c-guitar.mp3';
import D_guitar from '../audio/d-guitar.mp3';
import E_guitar from '../audio/e-guitar.mp3';
import F_guitar from '../audio/f-guitar.mp3';
import G_guitar from '../audio/g-guitar.mp3';
import A_guitar from '../audio/a-guitar.mp3';
import B_guitar from '../audio/b-guitar.mp3';

export { C };
export { D };
export { E };
export { F }; 
export { G };
export { A };
export { B };

// guitar - chord progressions
export { C_guitar };
export { D_guitar };
export { E_guitar };
export { F_guitar };
export { G_guitar };
export { A_guitar };
export { B_guitar };


// type == guitar or organ
export function getToneSample(modeName, type) {
  const isGuitar = type === 'guitar';
  switch (modeName) {
    case 'ionian': return isGuitar ? C_guitar : C;
    case 'dorian': return isGuitar ? D_guitar : D;
    case 'phrygian': return isGuitar ? E_guitar : E;
    case 'lydian': return isGuitar ? F_guitar : F;
    case 'mixolydian': return isGuitar ? G_guitar : G;
    case 'aeolian': return isGuitar ? A_guitar : A;
    case 'locrian': return isGuitar ? B_guitar : B;
    default: return 'BLERGH';
  }
}
